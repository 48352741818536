import { DateTime } from "luxon";
import React from "react";
import { Link } from "react-router-dom";
import tools from "../../../../helpers/tools";
import ProducerButton from "../../../common/ProducerButton/ProducerButton";
import GreyCard from "../../Dashboard/GreyCard";
import DownloadDocument from "./DownloadDocument";
import DocumentTimeline from "./DocumentTimeline/DocumentTimeline";
import useStore from "../../../../context/useStore";
import { sidenavActions } from "../../../../context/actions/sidenavActions";

const ClientItem = ({ policy, idx, documents, hideCompany = false }) => {
  const producer = policy.producer2;
  const [state, dispatch] = useStore();
  const sActions = sidenavActions(state, dispatch);

  const openDocumentSidenav = ({ name, documents, policy }) => {
    console.log("DOC INSIDE", documents);

    sActions.updateSidenav({
      isOpen: true,
      content: (
        <div>
          <div className="mb-4">
            <h2 style={{ fontSize: 16, fontWeight: "600", color: "black" }}>
              Historique des documents
            </h2>
            <h4 className="mt-3 mb-0 p-0">{name}</h4>
            <div
              className="d-flex flex-row align-items-center"
              style={{ gap: 5 }}
            >
              <div>
                <div className="badge badge-primary">
                  {policy.productType.name}
                </div>
              </div>
              <div className="text-muted">{policy.client.company}</div>
            </div>
          </div>
          <DocumentTimeline
            documents={documents}
            renderDocument={(document) => (
              <DownloadDocument
                document={document}
                productName={policy.productType.name}
                companyName={policy.client.company}
                name={name}
                className="w-100 btn-sm"
              />
            )}
          />
        </div>
      ),
    });
  };

  return (
    <div
      className={`animated fadeInUp faster col-12 px-0 mb-3  ${
        idx % 2 == 0 ? "pl-lg-0 pr-lg-2" : "pr-lg-0 pl-lg-2"
      }`}
    >
      <GreyCard paddingClassname=" p-3">
        <div
          // onClick={() => setShowDetail(!showDetail)}
          className="w-100 d-flex flex-column flex-md-row justify-content-between align-items-start"
        >
          <div>
            <div
              className="text-success font-weight-bold"
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                fontSize: 18,
              }}
            >
              {hideCompany
                ? policy.productType?.businessName
                : policy.client.company}
            </div>
            {/* <i
              className={`fa fa-chevron-${
                showDetail ? "up" : "down"
              } text-primary`}
              style={{ fontSize: 18 }}
            /> */}
            <div className="d-flex align-items-start justify-content-start flex-column mt-2">
              {!hideCompany && <span>{policy.productType?.businessName}</span>}
              <span>{policy.company?.name}</span>
              <strong>{policy.title}</strong>
              <strong>{policy.identification}</strong>
              {producer && (
                <div className="d-flex flex-row">
                  <span className="mr-2" style={{ whiteSpace: "nowrap" }}>
                    Gestionnaire :{" "}
                  </span>
                  <ProducerButton producer={producer} />
                </div>
              )}
            </div>
            <div
              className={`d-flex align-items-start justify-content-start flex-column  w-100 mt-3`}
              style={{
                overflow: "hidden",
                transition: "200ms",
                // maxHeight: showDetail ? "500px" : "0px",
              }}
            >
              {policy.isGozen && (
                <strong className="d-flex align-items-end justify-content-start">
                  Garanties {policy.isGozenPremium && "étendues"} activées
                  <Link
                    to="/protection-juridique?garantiesrenforcees"
                    className="btn btn-link py-0 pl-2 pr-0"
                    style={{
                      fontSize: 14,
                      textTransform: "lowercase",
                      textDecoration: "underline",
                    }}
                  >
                    en savoir +
                  </Link>
                </strong>
              )}
              {policy.options?.includes("OPTION_RC") && (
                <span>Recouvrement de créances</span>
              )}
              {policy.options?.includes("OPTION_FS") && (
                <span>Fiscal & social</span>
              )}
              {policy.isGozen ? (
                <span>
                  Chiffre d'affaires :{" "}
                  {tools.truenumberWithSpaces(policy.client.turnover)} €
                </span>
              ) : null}

              <strong>{policy.reference}</strong>
              <i>{policy.product?.businessName}</i>
              <span>
                Souscrit·e le{" "}
                {DateTime.fromISO(policy.effectDate).toFormat("dd/MM/yyyy")}
              </span>
              {policy.lastAmendment?.mainEcheance && (
                <span>
                  Échéance principale le{" "}
                  <strong>
                    {DateTime.fromISO(
                      policy.lastAmendment.mainEcheance
                    ).toFormat("dd/MM")}
                  </strong>
                </span>
              )}
              {policy.lastReceipt?.effectDate &&
                policy.lastReceipt.premiumWithVat && (
                  <span>
                    Du{" "}
                    {DateTime.fromISO(policy.lastReceipt.effectDate).toFormat(
                      "dd/MM/yyyy"
                    )}{" "}
                    au{" "}
                    {DateTime.fromISO(policy.lastReceipt.endDate).toFormat(
                      "dd/MM/yyyy"
                    )}{" "}
                    :{" "}
                    <strong>
                      {policy.lastReceipt.premiumWithVat}
                      {" €"}
                    </strong>
                  </span>
                )}

              {policy.isGozen && (
                <div className="mt-4 w-100">
                  {policy.isGozen && policy.lastAmendment?.canRenew && (
                    <div className="">
                      <Link
                        className="btn btn-primary btn-sm font-weight-bold"
                        to={"/contracts/" + policy.id + "/renew"}
                        style={{
                          height: 29,
                          fontSize: 14,
                        }}
                      >
                        <i className="fa fa-sync mr-2" />
                        Renouveler ce contrat
                      </Link>
                    </div>
                  )}
                  {policy.isGozen && !policy.lastAmendment?.canRenew && (
                    <div className="">
                      <Link
                        className="btn btn-primary btn-sm font-weight-bold"
                        to={"/contracts/" + policy.id}
                        style={{
                          height: 29,
                          fontSize: 14,
                        }}
                      >
                        <i className="fa fa-edit mr-2" />
                        Modifier ce contrat
                      </Link>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="mt-4 d-flex flex-column" style={{ gap: 5 }}>
            {documents?.contrat?.length > 0 && (
              <>
                {documents?.contrat?.length > 1 ? (
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() =>
                      openDocumentSidenav({
                        documents: documents?.contrat,
                        policy: policy,
                        name: "Contrat",
                      })
                    }
                  >
                    <i className="fa fa-download mr-2" />
                    Contrat
                  </button>
                ) : (
                  <DownloadDocument
                    document={documents.contrat[0]}
                    productName={policy.productType.name}
                    companyName={policy.client.company}
                    name="Contrat"
                    className="w-100 btn-sm"
                  />
                )}
              </>
            )}
            {documents?.attestation?.length > 0 && (
              <>
                {documents?.attestation?.length > 1 ? (
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() =>
                      openDocumentSidenav({
                        documents: documents?.attestation,
                        policy: policy,
                        name: "Attestation",
                      })
                    }
                  >
                    <i className="fa fa-download mr-2" />
                    Attestation
                  </button>
                ) : (
                  <DownloadDocument
                    document={documents.attestation[0]}
                    productName={policy.productType.name}
                    companyName={policy.client.company}
                    name="Attestation"
                    className="w-100 btn-sm"
                  />
                )}
              </>
            )}
            {documents?.conditions?.length > 0 && (
              <>
                {documents?.conditions?.length > 1 ? (
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() =>
                      openDocumentSidenav({
                        documents: documents?.conditions,
                        policy: policy,
                        name: "Conditions",
                      })
                    }
                  >
                    <i className="fa fa-download mr-2" />
                    Conditions
                  </button>
                ) : (
                  <DownloadDocument
                    document={documents.conditions[0]}
                    productName={policy.productType.name}
                    companyName={policy.client.company}
                    name="Conditions"
                    className="w-100 btn-sm"
                  />
                )}
              </>
            )}
          </div>
        </div>
      </GreyCard>
    </div>
  );
};

export default ClientItem;
