import React, { useEffect, useState } from "react";
import dataService from "../../../../helpers/dataService";
import Loader from "../../../common/Loader";
import ClientItem from "./ClientItem";
import RenewableQuittances from "../RenewablePolicies/RenewableQuittances";

const SimpleView = ({
  users,
  onlyGozen = false,
  setNbClients = () => {},
  embeded = false,
  setAllLoaded = () => {},
}) => {
  const [policies, setPolicies] = useState([]);
  const [clients, setClients] = useState([]);
  const [errors, setErrors] = useState({});
  const [policiesDocuments, setPoliciesDocuments] = useState({});
  const [isDocumentsLoaded, setIsDocumentsLoaded] = useState(false);
  const [noPolicy, setNoPolicy] = useState(false);

  useEffect(() => {
    var allPolicies = [];
    var allClients = [];
    users.forEach((user) => {
      user.clients.map((client) => {
        allPolicies = allPolicies.concat(
          client.policies.map((c) => ({ ...c, user, client }))
        );
        if (
          client.policies.filter((p) => {
            if (onlyGozen) {
              return p.isGozen;
            }
            return true;
          }).length > 0
        )
          allClients.push(client);
      });
    });
    setPolicies(allPolicies);
    setClients(allClients);
    setNbClients(
      allPolicies.filter((p) => {
        if (onlyGozen) {
          return p.isGozen;
        }
        return true;
      }).length
    );
  }, [users]);

  useEffect(() => {
    if (policies.length) {
      getDocuments();
      setNoPolicy(false);
    } else {
      setNoPolicy(true);
      setIsDocumentsLoaded(true);
    }
  }, [policies]);

  const getDocuments = async () => {
    let promises = [];
    policies.forEach((pol) => {
      promises.push(getPolicyDocuments(pol.id));
    });

    Promise.all(promises)
      .then((values) => {
        var extractDoc = {};
        values.forEach((val) => {
          extractDoc[val.policyId] = val.datas;
        });
        setPoliciesDocuments(extractDoc);

        setIsDocumentsLoaded(true);
      })
      .catch((err) => {
        setErrors(err);
      });
  };

  const getPolicyDocuments = (policyId) => {
    return new Promise((resolve, reject) => {
      dataService.get(
        `policies/${policyId}/files`,
        (datas) => {
          var fdata = {
            contrat: false,
            attestation: false,
            conditions: false,
          };
          datas.forEach((d) => {
            if (d[0].fileName && d[0].fileType.code) {
              switch (d[0].fileType.code) {
                case "CP":
                  fdata.contrat = d;
                  break;
                case "ATT_ASSU_CONT":
                  fdata.attestation = d;
                  break;
                case "DG":
                  fdata.conditions = d;
                  break;
                default:
                  break;
              }
            }
          });
          resolve({ policyId, datas: fdata });
          //setDocuments(fdata);
        },
        (err) => {
          reject(err);
        }
      );
    });
  };

  useEffect(() => {
    if (isDocumentsLoaded) {
      setAllLoaded();
    }
  }, [isDocumentsLoaded]);

  return (
    <div className={`animated fadeIn  row mx-0 ${!embeded ? "pb-5 mb-5" : ""}`}>
      {!embeded && (
        <>
          <p className="mb-0 px-2" style={{ fontSize: 14 }}>
            <strong className="">
              Vos contrats d’assurance en un coup d’oeil.{" "}
              <img
                style={{ position: "relative", bottom: 5, width: 29 }}
                src="/images/client-emoji.png"
                alt="emoji"
              />
            </strong>
          </p>
          <p className="px-2" style={{ fontSize: 14 }}>
            Déroulez chaque carte pour accéder aux documents et effectuer des
            actions (modification, renouvellement, etc.).
          </p>
        </>
      )}
      {noPolicy && isDocumentsLoaded && (
        <p className="px-2">
          <strong>Vous ne gérez aucun contrat pour le moment.</strong>
        </p>
      )}
      {isDocumentsLoaded ? (
        <>
          {clients.map((client, clientk) => {
            const clientPolicies = policies
              .filter((p) => p.client.id === client.id)
              .filter((p) => {
                if (onlyGozen) {
                  return p.isGozen;
                }
                return true;
              });
            return (
              <div className="col-12" key={`cli${clientk}`}>
                <h2
                  className="h1 mb-3 mt-5"
                  style={{ textTransform: "uppercase" }}
                >
                  {client.company}
                </h2>
                <div className="row">
                  {clientPolicies.length === 0 ? (
                    <div className="col-12 font-weight-bold">Aucun contrat</div>
                  ) : (
                    clientPolicies
                      .sort((a, b) =>
                        a.client.company > b.client.company ? 1 : -1
                      )
                      .map((policy, policyk) => (
                        <ClientItem
                          key={`simpleclient${policyk}`}
                          policy={policy}
                          idx={policyk}
                          documents={policiesDocuments[policy.id]}
                          hideCompany
                        />
                      ))
                  )}
                </div>
              </div>
            );
          })}
        </>
      ) : !noPolicy ? (
        <div className="d-center w-100">
          <Loader size={70} />
        </div>
      ) : null}
    </div>
  );
};

export default SimpleView;
