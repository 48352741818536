import React from 'react';
import tools from '../../../../../helpers/tools';
import './DocumentTimeline.css';

const DocumentTimeline = ({ documents, renderDocument }) => {
  const sortedDocuments = [...documents].sort(
    (a, b) => new Date(b.uploadedAt) - new Date(a.uploadedAt)
  );

  return (
    <div className="document-timeline-container">
      {sortedDocuments.map((document, index) => (
        <div key={index} className="document-timeline-item">
          <div className="document-timeline-date">
            {tools.formatDate(document.uploadedAt)}
          </div>
          <div className="document-timeline-content">
            {renderDocument(document)}
          </div>
        </div>
      ))}
    </div>
  );
};

export default DocumentTimeline;
