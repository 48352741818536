import { DateTime } from "luxon";
import React, { useEffect } from "react";
import styled from "styled-components";
import tools from "../../../../../../../../../../helpers/tools";
import Loader from "../../../../../../../../../common/Loader";
import DropZone from "./DropZone";
import OlderDocs from "./OlderDocs";
import useBPDocumentItem from "./useBPDocumentItem";

const BPDocumentItem = ({ doc, checkCanModify = () => {}, dataPriv }) => {
  const {
    document,
    isDownloadingDocId,
    isRemovingDocId,
    isUploadingDocId,
    showOlder,
    docInfos,
    otherListRef,
    actions: { onDownload, onRemove, onUpload, toggleShowOlder },
  } = useBPDocumentItem(doc);

  useEffect(() => {
    if (showOlder) otherListRef.current.scrollTop = 180;
  }, [showOlder]);

  return (
    <StyledDocumentItem className="shadow-sm border" ref={otherListRef}>
      {docInfos.id && (
        <div className="doc__count bg-primary text-white font-weight-bold">
          {document.length}
        </div>
      )}
      <p className="doc__date my-2">
        {docInfos.uploadedAt ? (
          <>
            Envoyé le{" "}
            {DateTime.fromISO(docInfos.uploadedAt).toFormat("dd/MM/yyyy")}
          </>
        ) : (
          <>___</>
        )}
      </p>
      <div className="doc__name mb-2 font-weight-bold">
        {docInfos.fileType.name}
      </div>

      {checkCanModify(docInfos) && tools.canUploadSpecialDocument(docInfos) && (
        <DropZone
          dataPriv={dataPriv}
          onDropFiles={onUpload}
          isUploading={isUploadingDocId}
        />
      )}
      {docInfos.fileName ? (
        <div className="d-center flex-column ">
          <button
            className="btn btn-sm btn-primary mb-1 w-100"
            onClick={() => onDownload(docInfos)}
          >
            {isDownloadingDocId == docInfos.id ? <Loader /> : "Télécharger"}
          </button>
          {checkCanModify(docInfos) &&
            tools.canDeleteSpecialDocument(docInfos) && (
              <button
                data-priv={dataPriv}
                className="btn btn-sm btn-link text-danger mb-1 w-100"
                onClick={() => onRemove(docInfos.id)}
              >
                {isRemovingDocId == docInfos.id ? <Loader /> : "Supprimer"}
              </button>
            )}
          {document.length > 1 && (
            <button
              className="btn btn-link btn-sm w-100 text-dark font-weight-normal"
              onClick={toggleShowOlder}
            >
              {document.length - 1} autre(s){" "}
              <i className={`fa fa-chevron-${showOlder ? "up" : "down"}`} />
            </button>
          )}
        </div>
      ) : (
        <p className="doc__date my-2">Aucun document</p>
      )}

      {showOlder && (
        <OlderDocs
          dataPriv={dataPriv}
          checkCanModify={checkCanModify}
          document={document}
          onDownload={onDownload}
          onRemove={onRemove}
          isDownloadingDocId={isDownloadingDocId}
          isRemovingDocId={isRemovingDocId}
        />
      )}
    </StyledDocumentItem>
  );
};

const StyledDocumentItem = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
  border-radius: 20px;
  background: white;
  overflow-y: auto;
  scroll-behavior: smooth;
  position: relative;

  & .doc__count {
    position: absolute;
    left: 0;
    top: 0;
    padding: 10px;
    border-bottom-right-radius: 20px;
    width: 30px;
    text-align: center;
  }

  & .doc__name {
    height: 60px;
    overflow: hidden;
    font-size: 14px;
    text-align: center;
  }

  & .doc__date {
    font-weight: 200;
    text-align: center;
    font-size: 12px;
  }
`;

export default BPDocumentItem;
