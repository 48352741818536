import { useEffect } from "react";
import useCompanyItem from "../../useCompanyItem";
import BPDocumentItem from "./BPDocumentItem/BPDocumentItem";
import useCompanyDocuments from "./useCompanyDocuments";

const BPDocuments = () => {
  const { company } = useCompanyItem();
  const { documentState, actions } = useCompanyDocuments();

  useEffect(() => {
    actions.getCompanyDocuments(company.id);
  }, []);

  function checkDocActionAvailable(doc) {
    return ["Client", "UserCustomer"].includes(doc.entity.ref);
  }

  return (
    <div className="row">
      {documentState.companyDocuments.map((document) => {
        return (
          <div
            className="col-12 col-md-6 col-lg-4 p-2"
            key={`docgroup${document.map((d) => d.fileType.name).join("-")}`}
          >
            <BPDocumentItem
              doc={document}
              checkCanModify={checkDocActionAvailable}
              dataPriv={`app_modifycompanydocs-${company.id}`}
            />
          </div>
        );
      })}
    </div>
  );
};

export default BPDocuments;
